<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">IATF16949产品审核（VDA6.5）</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <div class="PList">
        <p style="text-indent: 2em">
          IATF16949汽车质量管理体系中的产品审核，是IATF16949每年内部审核的一部分。
        </p>
        <p style="text-indent: 2em">
          IATF16949的内部审核分为：①体系审核；②制造过程审核；③产品审核。
        </p>
        <p>
          产品审核应采用顾客要求的产品审核方法。如果顾客未指定，组织应确定自己的审核方法。而《VDA6.5产品审核》是常见的产品审核方法。因为VDA6.5是德国汽车工业联合会（VDA）制定的德国汽车工业质量标准的第五部分，即产品审核，简称VDA6.5，产品审核是指对产品的质量特性进行评定，以确定是否符合规定的要求。
        </p>
        <p>我司经验丰富的咨询顾问将现场指导企业内部审核，其意义在于：</p>
        <p>①发现体系运行缺陷，确保顺利通过认证机构审核。</p>
        <p>②为企业持续有效运行体系提供建设性建议。</p>
        <p>③提高企业员工体系维护的能力。</p>
      </div>
      <br />
      <br />
      <p class="PTitle">我们的优势</p>
      <p class="SmallP" style="text-indent: 2em">
        辅导国内第一家汽车零部件企业通过汽车质量管理体系的咨询公司，深耕汽车行业20多年。
        我司成立于1997年，专业从事国际标准管理体系咨询和培训业务，20多年来服务的客户中不乏世界500强企业、大型港台企业、著名的上市公司和服务公司等，客户类型覆盖广泛，目前已成为省内客户及整合一体化体系最多的咨询公司。
      </p>
      <br />
      <br />
      <p class="PTitle">审核的步骤：</p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/60.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="PTitle">体系审核、制造过程审核、产品审核的区别:</p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/64.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="PTitle LeftBor">产品审核的特点:</p>
      <div class="PList" style="margin-top: 10px">
        <p>1. 在一个生产工序结束后，交给下一个顾客（内部/外部）前。</p>
        <p>2. 始终以客户和最终用户的眼光对产品的性能、尺寸和外观进行检查。</p>
        <p>3. 研究最新的检测方法或技术方法，以便发现缺陷。</p>
        <p>4. 为实现可比性要保持评定方法较长时间不变。</p>
      </div>
      <br />
      <p class="PTitle">产品审核和全尺寸和功能试验的区别:</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/65.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="PTitle LeftBor">IATF16949产品审核（VDA6.5）重点：</p>
      <div class="PList" style="margin-top: 10px">
        <p>1. 产品审核范围。</p>
        <p>2. 产品特性的识别。</p>
        <p>3. 对VDA6.5标准的理解程度。</p>
        <p>4. 产品审核的策划。</p>
        <p>5. 产品审核检查表的编制。</p>
        <p>6. 缺陷分类和权重。</p>
        <p>7. 质量特征指数QKZ的计算。</p>
        <p>8. 审核问题整改。</p>
        <p>9. 审核总结及分析。</p>
        <p>10. .....</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>